import { forwardRef } from 'react';
import { isFunction } from 'lodash';
import styled, { useTheme } from 'styled-components';

const withStyled = (Component) => {
    const StyledComponent = styled(Component)`
        display: ${({ $d }) => $d};
        width: ${({ $w }) => $w};
        height: ${({ $h }) => $h};

        background: ${({ $bg }) => $bg};
        background-color: ${({ $bgC }) => $bgC};
        background-image: ${({ $bgI }) => $bgI};
        background-position: ${({ $bgP }) => $bgP};
        background-repeat: ${({ $bgR }) => $bgR};
        background-size: ${({ $bgS }) => $bgS};

        color: ${({ $color }) => $color};
        text-transform: ${({ $textTransform }) => $textTransform};

        justify-content: ${({ $justify }) => $justify};
        align-items: ${({ $items }) => $items};
        flex: ${({ $flex }) => $flex};
        flex-direction: ${({ $direction }) => $direction};
        flex-grow: ${({ $flexGrow }) => $flexGrow};

        grid-template-columns: ${({ $cols }) => $cols};
        grid-template-rows: ${({ $rows }) => $rows};

        grid-gap: ${({ $gap }) => $gap};
        grid-row-gap: ${({ $rowGap }) => $rowGap};
        grid-column-gap: ${({ $colGap }) => $colGap};

        margin: ${({ $m }) => $m};
        margin-bottom: ${({ $my, $mb }) => $mb || $my};
        margin-top: ${({ $my, $mt }) => $mt || $my};
        margin-left: ${({ $mx, $ml }) => $ml || $mx};
        margin-right: ${({ $mx, $mr }) => $mr || $mx};
        cursor: ${({ $cursor }) => $cursor};

        padding: ${({ $p }) => $p};
        padding-bottom: ${({ $py, $pb }) => $pb || $py};
        padding-top: ${({ $py, $pt }) => $pt || $py};
        padding-left: ${({ $px, $pl }) => $pl || $px};
        padding-right: ${({ $px, $pr }) => $pr || $px};

        border: ${({ $b }) => $b};
        border-bottom: ${({ $by, $bb }) => $bb || $by};
        border-top: ${({ $by, $bt }) => $bt || $by};
        border-left: ${({ $bx, $bl }) => $bl || $bx};
        border-right: ${({ $bx, $br }) => $bx || $br};

        border-color: ${({ $borderColor }) => $borderColor};
        border-style: ${({ $borderStyle }) => $borderStyle};
        border-width: ${({ $borderWidth }) => $borderWidth};

        border-radius: ${({ $rounded }) => $rounded};

        z-index: ${({ $z }) => $z};
        overflow: ${({ $overflow }) => $overflow};
        overflow-x: ${({ $overflowX }) => $overflowX};
        overflow-y: ${({ $overflowY }) => $overflowY};

        position: ${({ $position }) => $position};
        transform: ${({ $transform }) => $transform};
        top: ${({ $top }) => $top};
        left: ${({ $left }) => $left};
        right: ${({ $right }) => $right};
        bottom: ${({ $bottom }) => $bottom};
        opacity: ${({ $opacity }) => $opacity};
        transition: ${({ $transition }) => $transition};
        text-align: ${({ $text }) => $text};
        box-shadow: ${({ $shadow }) => $shadow};

        visibility: ${({ $visibility }) => $visibility};
        outline: ${({ $outline }) => $outline};
        line-height: ${({ $leading }) => $leading};
        font-size: ${({ $fontSize }) => $fontSize};
        font-weight: ${({ $fontWeight }) => $fontWeight};
        word-break: ${({ $wordBreak }) => $wordBreak};
        text-overflow: ${({ $textOverflow }) => $textOverflow};
        resize: ${({ $resize }) => $resize};
        white-space: ${({ $whiteSpace }) => $whiteSpace};
    `;

    function StyledComponentWrapper(
        {
            d,
            w,
            h,
            bg,
            bgC,
            bgI,
            bgP,
            bgR,
            bgS,
            justify,
            items,
            flex,
            direction,
            cols,
            rows,
            gap,
            colGap,
            rowGap,
            flexGrow,
            m,
            mt,
            mb,
            mr,
            ml,
            my,
            mx,
            p,
            pt,
            pb,
            pr,
            pl,
            py,
            px,
            b,
            bt,
            bb,
            by,
            bx,
            rounded,
            z,
            overflow,
            position,
            transform,
            transition,
            top,
            left,
            right,
            bottom,
            opacity,
            text,
            overflowX,
            overflowY,
            shadow,
            cursor,
            visibility,
            outline,
            color,
            borderColor,
            borderStyle,
            borderWidth,
            textTransform,
            fontSize,
            leading,
            fontWeight,
            wordBreak,
            textOverflow,
            resize,
            whiteSpace,
            ...rest
        },
        ref,
    ) {
        const theme = useTheme();
        return (
            <StyledComponent
                ref={ref}
                $d={isFunction(d) ? d({ theme }) : d}
                $w={isFunction(w) ? w({ theme }) : w}
                $h={isFunction(h) ? h({ theme }) : h}
                $bg={isFunction(bg) ? bg({ theme }) : bg}
                $bgC={isFunction(bgC) ? bgC({ theme }) : bgC}
                $bgI={isFunction(bgI) ? bgI({ theme }) : bgI}
                $bgP={isFunction(bgP) ? bgP({ theme }) : bgP}
                $bgR={isFunction(bgR) ? bgR({ theme }) : bgR}
                $bgS={isFunction(bgS) ? bgS({ theme }) : bgS}
                $justify={isFunction(justify) ? justify({ theme }) : justify}
                $items={isFunction(items) ? items({ theme }) : items}
                $flex={isFunction(flex) ? flex({ theme }) : flex}
                $direction={isFunction(direction) ? direction({ theme }) : direction}
                $cols={isFunction(cols) ? cols({ theme }) : cols}
                $rows={isFunction(rows) ? rows({ theme }) : rows}
                $flexGrow={isFunction(flexGrow) ? flexGrow({ theme }) : flexGrow}
                $gap={isFunction(gap) ? gap({ theme }) : gap}
                $rowGap={isFunction(rowGap) ? rowGap({ theme }) : rowGap}
                $colGap={isFunction(colGap) ? colGap({ theme }) : colGap}
                $m={isFunction(m) ? m({ theme }) : m}
                $mt={isFunction(mt) ? mt({ theme }) : mt}
                $mb={isFunction(mb) ? mb({ theme }) : mb}
                $mr={isFunction(mr) ? mr({ theme }) : mr}
                $ml={isFunction(ml) ? ml({ theme }) : ml}
                $my={isFunction(my) ? my({ theme }) : my}
                $mx={isFunction(mx) ? mx({ theme }) : mx}
                $p={isFunction(p) ? p({ theme }) : p}
                $pt={isFunction(pt) ? pt({ theme }) : pt}
                $pb={isFunction(pb) ? pb({ theme }) : pb}
                $pl={isFunction(pl) ? pl({ theme }) : pl}
                $pr={isFunction(pr) ? pr({ theme }) : pr}
                $py={isFunction(py) ? py({ theme }) : py}
                $px={isFunction(px) ? px({ theme }) : px}
                $b={isFunction(b) ? b({ theme }) : b}
                $bt={isFunction(bt) ? bt({ theme }) : bt}
                $bb={isFunction(bb) ? bb({ theme }) : bb}
                $by={isFunction(by) ? by({ theme }) : by}
                $bx={isFunction(bx) ? bx({ theme }) : bx}
                $rounded={isFunction(rounded) ? rounded({ theme }) : rounded}
                $z={isFunction(z) ? z({ theme }) : z}
                $overflow={isFunction(overflow) ? overflow({ theme }) : overflow}
                $overflowX={isFunction(overflowX) ? overflowX({ theme }) : overflowX}
                $overflowY={isFunction(overflowY) ? overflowY({ theme }) : overflowY}
                $position={isFunction(position) ? position({ theme }) : position}
                $transform={isFunction(transform) ? transform({ theme }) : transform}
                $top={isFunction(top) ? top({ theme }) : top}
                $left={isFunction(left) ? left({ theme }) : left}
                $right={isFunction(right) ? right({ theme }) : right}
                $bottom={isFunction(bottom) ? bottom({ theme }) : bottom}
                $opacity={isFunction(opacity) ? opacity({ theme }) : opacity}
                $transition={isFunction(transition) ? transition({ theme }) : transition}
                $text={isFunction(text) ? text({ theme }) : text}
                $shadow={isFunction(shadow) ? shadow({ theme }) : shadow}
                $cursor={isFunction(cursor) ? cursor({ theme }) : cursor}
                $visibility={isFunction(visibility) ? visibility({ theme }) : visibility}
                $outline={isFunction(outline) ? outline({ theme }) : outline}
                $color={isFunction(color) ? color({ theme }) : color}
                $borderColor={isFunction(borderColor) ? borderColor({ theme }) : borderColor}
                $borderStyle={isFunction(borderStyle) ? borderStyle({ theme }) : borderStyle}
                $borderWidth={isFunction(borderWidth) ? borderWidth({ theme }) : borderWidth}
                $textTransform={isFunction(textTransform) ? textTransform({ theme }) : textTransform}
                $fontSize={isFunction(fontSize) ? fontSize({ theme }) : fontSize}
                $leading={isFunction(leading) ? leading({ theme }) : leading}
                $fontWeight={isFunction(fontWeight) ? fontWeight({ theme }) : fontWeight}
                $wordBreak={isFunction(wordBreak) ? wordBreak({ theme }) : wordBreak}
                $textOverflow={isFunction(textOverflow) ? textOverflow({ theme }) : textOverflow}
                $resize={isFunction(resize) ? resize({ theme }) : resize}
                $whiteSpace={isFunction(whiteSpace) ? whiteSpace({ theme }) : whiteSpace}
                {...rest}
            />
        );
    }

    return forwardRef(StyledComponentWrapper);
};

export default withStyled;
