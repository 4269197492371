import { createSelector, createSlice } from '@reduxjs/toolkit';
import { PLAN_TERMS, PLANS } from '../../../configs/constants';
import { RootState } from '../../store';
import { QUOTAS } from '../../../pages/Payment/constants';
import { userApi } from '../user/api';
import moment from 'moment';
import { isNaN } from 'lodash';

export const paymentSlice = createSlice({
    name: 'payment',
    initialState: {
        term: PLAN_TERMS.THREE_MONTHS,
        plan: PLANS.INDIVIDUAL,
        quota: 0,
        remainingSubscribedCredit: 0,
        remainingDays: 0,
        isLoading: false,
    },
    reducers: {
        setTerm: (state, action) => {
            state.term = action.payload;
        },
        setPlan: (state, action) => {
            state.plan = action.payload;
        },
        setQuota: (state, action) => {
            state.quota = action.payload;
        },
        setRemainingSubscribedCredit: (state, action) => {
            state.remainingSubscribedCredit = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(userApi.endpoints.getUser.matchFulfilled, (state, action) => {
                console.log(action);

                if (!action.payload) return;
                const { lastPaymentEndAt, lastTotalPrice, lastPaymentPrice, paymentTerm } = action.payload;
                const today = moment();
                state.remainingDays = moment(lastPaymentEndAt).diff(today, 'days');

                const lastPrice = lastTotalPrice || lastPaymentPrice || 0;

                if (!lastPrice || !lastPaymentEndAt) {
                    state.remainingSubscribedCredit = 0;
                    return;
                }
                const remainingSubcribedCredit = (
                    (lastPrice * moment(lastPaymentEndAt).diff(today, 'months')) /
                    paymentTerm
                ).toFixed(2);

                if (isNaN(remainingSubcribedCredit)) {
                    state.remainingSubscribedCredit = 0;
                    return;
                }

                state.remainingSubscribedCredit =
                    remainingSubcribedCredit > lastPrice ? lastPrice : remainingSubcribedCredit;
            })

            // and provide a default case if no other handlers matched
            .addDefaultCase((state, action) => {
                //
            });
    },
});

export const { setTerm, setPlan, setQuota } = paymentSlice.actions;

export const selectTerm = (state: RootState) => state.payment.term;
export const selectPlan = (state: RootState) => state.payment.plan;
export const selectQuota = (state: RootState) => state.payment.quota;
export const selectRemainingSubscribedCredit = (state: RootState) => state.payment.remainingSubscribedCredit;
export const selectRemainingDays = (state: RootState) => state.payment.remainingDays;
export const selectTotalPrice = (state: RootState) => {
    const paymentQuota =
        (QUOTAS[`_${state.payment.term}`]?.[state.payment.plan]?.[`_${state.payment.quota}`] || 0) -
        state.payment.remainingSubscribedCredit;

    return paymentQuota > 0 ? paymentQuota : 0;
};

export const selectIsLoading = (state: RootState) => state.payment.isLoading;

export const selectCanUpgrade = createSelector(selectTotalPrice, (totalPrice) => {
    return totalPrice > 0;
});
