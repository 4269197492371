// import type from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import type {
    FetchArgs,
    FetchBaseQueryArgs,
    FetchBaseQueryError,
    FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { appActions } from '../redux/slices/app/appSlice';
import request from './request';
import { BaseQueryApi, BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { signOutUser } from '../redux/actions/authApi';
import axios from 'axios';
import { setToken } from '../redux/slices/auth/authSlice';

export const axiosBaseQuery =
    ({
        baseUrl,
        prepareHeaders,
    }: FetchBaseQueryArgs): BaseQueryFn<FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta> =>
    async ({ url, method, body, params, headers }, api) => {
        try {
            const finalHeaders = (await prepareHeaders(new Headers(), api)) || new Headers();
            const requestHeaders = {};

            finalHeaders.forEach((value, key) => {
                requestHeaders[key] = value;
            });

            const result = await request({
                url: baseUrl + url,
                method,
                data: body,
                params,
                headers: {
                    ...requestHeaders,
                    ...((headers as Record<string, string>) || {}),
                },
            });
            return { data: result.data };
        } catch (err) {
            console.error(err);

            return {
                error: {
                    status: err.response ? err.response.status : null,
                    data: err.response ? err.response.data : err.message,
                },
            };
        }
    };

export const baseQueryWithInterceptor =
    (baseQueryFn: BaseQueryFn) => async (args: FetchBaseQueryArgs, api: BaseQueryApi, extraOptions: object) => {
        const { dispatch } = api;
        try {
            const result = await baseQueryFn(args, api, extraOptions);
            console.log(result);

            // @ts-ignore
            if (result && result.error && result.error.status === 401) {
                const refreshToken = localStorage.getItem('refreshToken');
                await axios
                    .post(`${process.env.REACT_APP_API_BASE_URL}/refresh_token`, { refreshToken: refreshToken })
                    .then(({ data }) => {
                        console.log('data', data);
                        if (data.success) {
                            dispatch(setToken({ token: data.data.accessToken, refreshToken: data.data.refreshToken }));
                        } else {
                            api.dispatch(signOutUser());
                        }
                    })
                    .catch((err) => {
                        console.log('Refresh token error: ', err);
                        api.dispatch(signOutUser());
                    });
            }
            return result;
        } catch (error) {
            dispatch(appActions.setError(error.message));
            return { data: null };
        }
    };
