import { App as AntdApp } from 'antd';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux/es/exports';
import { store } from './redux/store';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ToastContainer } from 'react-toastify';

import './index.css';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <BrowserRouter>
        <AntdApp>
            <Provider store={store}>
                <GoogleOAuthProvider clientId={process.env.REACT_APP_GG_OAUTH2_CLIENT_ID}>
                    <App />
                    <ToastContainer />
                </GoogleOAuthProvider>
            </Provider>
        </AntdApp>
    </BrowserRouter>,
);
