import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { authApi, authWithGoogle, signOutUser } from '../../actions/authApi';

interface User {
    name: string;
    email: string;
    photoUrl: string;
}

interface AuthState {
    user: User | null;
    token: string | null;
    refreshToken: string | null;
    isAuthenticated: boolean;
    authResult: any;
    authError: string | null;
}

const tokenStorage = localStorage.getItem('token');
const refreshTokenStorage = localStorage.getItem('refreshToken') || null;
const userStorage = localStorage.getItem('user') || null;
const user = userStorage ? JSON.parse(userStorage) : null;
const defaultInitialState: AuthState = {
    user: null,
    token: null,
    refreshToken: null,
    isAuthenticated: false,
    authResult: null,
    authError: null,
};

const initialState = user
    ? {
          ...defaultInitialState,
          user,
          token: tokenStorage,
          refreshToken: refreshTokenStorage,
          isAuthenticated: !!tokenStorage,
      }
    : defaultInitialState;

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCredentials: (
            state,
            {
                payload: { user, token, refreshToken },
            }: PayloadAction<{ user: User; token: string; refreshToken: string }>,
        ) => {
            state.user = user;
            state.token = token;
            state.refreshToken = refreshToken;
            state.isAuthenticated = true;
        },
        setToken: (
            state,
            { payload: { token, refreshToken } }: PayloadAction<{ token: string; refreshToken: string }>,
        ) => {
            console.log(token, refreshToken);
            localStorage.setItem('token', token);
            localStorage.setItem('refreshToken', refreshToken);
            state.token = token;
            state.refreshToken = refreshToken;
        },
        setAuthRedirectResult: (state, { payload }: any) => {
            console.log('payload', payload);
            const currentUser: User = {
                name: payload.displayName,
                email: payload.email,
                photoUrl: payload.photoUrl,
            };
            localStorage.setItem('user', JSON.stringify(currentUser));
            state.user = currentUser;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(signOutUser.fulfilled, (state) => {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('user');
            state.user = null;
            state.token = null;
            state.refreshToken = null;
            state.isAuthenticated = false;
        });
        builder.addCase(signOutUser.rejected, (state) => {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('user');
            state.user = null;
            state.token = null;
            state.refreshToken = null;
            state.isAuthenticated = false;
        });
        builder.addCase(authWithGoogle.fulfilled, (state, { payload }) => {
            state.authResult = payload;
            state.authError = null;
        });
        builder.addCase(
            authWithGoogle.rejected,
            (
                state,
                {
                    payload,
                }: {
                    payload: any;
                },
            ) => {
                state.authError = payload.code;
            },
        );
        builder.addMatcher(authApi.endpoints.refreshToken.matchFulfilled, (state, { payload }) => {
            localStorage.setItem('token', payload.data.accessToken);
            localStorage.setItem('refreshToken', payload.data.refreshToken);
            state.token = payload.data.accessToken;
            state.refreshToken = payload.data.refreshToken;
        });
        builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
            localStorage.setItem('token', payload.data.accessToken);
            localStorage.setItem('refreshToken', payload.data.refreshToken);
            state.token = payload.data.accessToken;
            state.refreshToken = payload.data.refreshToken;
            state.isAuthenticated = true;
        });
    },
});

export const { setCredentials, setToken, setAuthRedirectResult } = authSlice.actions;

export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectAccessToken = (state: RootState) => state.auth.token;
export const selectRefreshToken = (state: RootState) => state.auth.refreshToken;
export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const selectAuthResult = (state: RootState) => state.auth.authResult;
export const selectAuthError = (state: RootState) => state.auth.authError;

export default authSlice.reducer;
