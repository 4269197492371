import { Navigate, Route, Routes } from 'react-router-dom';
import Payment from './pages/Payment';
import { PrivateRoute } from './PrivateRoute';
import { PATHS } from './configs/constants';
import FormManagement from './pages/FormManagement';
import AdminLayout from './layouts/AdminLayout';
import FormDetail from './pages/FormDetail';
import { useEffect } from 'react';
import { useRefreshTokenMutation } from './redux/actions/authApi';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated, selectRefreshToken } from './redux/slices/auth/authSlice';

import './App.css';
import './scss/global.scss';
import SubscriptionPage from './pages/Subscription';

function App() {
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const refreshToken = useSelector(selectRefreshToken);
    const [refreshTokenFunc] = useRefreshTokenMutation();
    useEffect(() => {
        if (isAuthenticated) {
            const time = 30 * 60000;
            const intervalId = setInterval(async () => {
                //Refresh token
                await refreshTokenFunc(refreshToken);
            }, time);

            return () => clearInterval(intervalId); //This is important
        }
    }, [isAuthenticated]);
    return (
        <div className="App">
            <Routes>
                <Route path="payment" element={<PrivateRoute component={Payment} />} />
                <Route path="/" element={<PrivateRoute component={AdminLayout} />}>
                    <Route path={'/'} element={<SubscriptionPage />} />
                    <Route path={PATHS.FORM_MANAGEMENT} element={<FormManagement />} />
                    <Route path={`${PATHS.FORM_MANAGEMENT}/:formId`} element={<FormDetail />} />
                </Route>
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </div>
    );
}

export default App;
