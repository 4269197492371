import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../redux/slices/auth/authSlice';
import {
    useAddTeamMemberMutation,
    useGetUserQuery,
    useLazyGetTeamMembersQuery,
    useRemoveTeamMemberMutation,
} from '../../redux/slices/user/api';
import { Alert, Button, Card, Col, Input, Row, Skeleton, Space, Table, Typography } from 'antd';
import { capitalize } from 'lodash';
import { PLANS } from '../../configs/constants';
import moment from 'moment';
import { DeleteOutlined } from '@ant-design/icons';

const { Text, Title } = Typography;

const SubscriptionPage = () => {
    const [memberInputValue, setMemberInputValue] = useState('');
    const currentUser = useSelector(selectCurrentUser);
    const {
        data: userResponse,
        isFetching,
        error,
        refetch: getUser,
    } = useGetUserQuery(currentUser?.email, {
        skip: !currentUser || !currentUser.email,
    });
    const [getTeamMembers, { data: members, isFetching: isGettingMembers, error: getTeamMemberError }] =
        useLazyGetTeamMembersQuery();
    const [addTeamMember, { data: addTeamMemberResult, isLoading: isAdding }] = useAddTeamMemberMutation();
    const [removeTeamMember, { data: removeTeamMemberResult, isLoading: isRemoving }] = useRemoveTeamMemberMutation();

    const { data } = userResponse || {};

    useEffect(() => {
        if (!data || data.plan !== PLANS.TEAM) return;
        getTeamMembers();
    }, [data]);

    useEffect(() => {
        if (!currentUser || !currentUser.email) return;
        getUser();
    }, [currentUser]);

    return (
        <>
            <Helmet>
                <title>PerformNoti: Subscription Management</title>
                <meta name="description" content="Subscription Management" />
            </Helmet>
            <Space size={16} direction="vertical" style={{ width: '100%' }}>
                {(error || getTeamMemberError) && (
                    <Alert message="Error: Something went wrong!" type="error" showIcon />
                )}
                <Row gutter={[8, 24]}>
                    <Col xs={24} md={16} lg={12}>
                        {isFetching && <Skeleton active />}
                        {!isFetching && data && (
                            <Typography style={{ textAlign: 'left' }}>
                                <Space size="large" direction="vertical">
                                    <Space direction="horizontal">
                                        <Title style={{ margin: 0 }} level={4}>
                                            Email Plan
                                        </Title>
                                    </Space>
                                    <Space direction="vertical">
                                        <Text>
                                            Your Plan: <Text strong>{capitalize(data.plan)}</Text>
                                        </Text>
                                        <Text>
                                            Quota: <Text strong>{capitalize(data.paidQuota)}</Text>
                                        </Text>
                                        <Text>
                                            Payment Term: <Text strong>{capitalize(data.paymentTerm)} Months</Text>
                                        </Text>
                                        <Text>
                                            Used Quota: <Text strong>{capitalize(data.usedQuota || 0)}</Text>
                                        </Text>
                                        {data.plan === PLANS.TEAM && (
                                            <Text>
                                                Team Used Quota:{' '}
                                                <Text strong>{capitalize(data.teamUsedQuota || 0)}</Text>
                                            </Text>
                                        )}
                                        {data.plan !== PLANS.FREE && (
                                            <Text>
                                                Expired at:{' '}
                                                <Text strong>
                                                    {moment(data.lastPaymentEndAt).format('MMM DD, YYYY')}
                                                </Text>
                                            </Text>
                                        )}
                                    </Space>
                                </Space>
                            </Typography>
                        )}
                    </Col>
                    <Col
                        xs={24}
                        md={16}
                        lg={12}
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        {isFetching && <Skeleton.Button active />}
                        {!isFetching && data && (
                            <Button
                                href="/payment"
                                type={data.plan === PLANS.FREE ? 'primary' : 'default'}
                                danger={data.plan === PLANS.FREE}
                            >
                                {data.plan === PLANS.FREE ? 'Upgrade Plan' : 'Change Plan'}
                            </Button>
                        )}
                    </Col>
                    <Col
                        span={24}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}
                    >
                        {isFetching && <Card style={{ width: '100%' }} loading />}
                        {!isFetching && data && data.plan === PLANS.TEAM && data.email === data.teamAdmin && (
                            <>
                                <Title style={{ margin: 0, marginBottom: 16 }} level={4}>
                                    Manage Team Members
                                </Title>
                                <Table
                                    style={{
                                        width: '100%',
                                    }}
                                    columns={[
                                        {
                                            title: 'Member email',
                                            dataIndex: 'email',
                                        },
                                        {
                                            title: 'Member used quota',
                                            dataIndex: 'usedQuota',
                                            width: 200,
                                            align: 'center',
                                        },
                                        {
                                            title: 'Action',
                                            width: 100,
                                            align: 'center',
                                            render: (text, record) => (
                                                <Button
                                                    danger
                                                    loading={isRemoving}
                                                    icon={<DeleteOutlined />}
                                                    onClick={async () => {
                                                        await removeTeamMember(record.email);
                                                        getTeamMembers();
                                                    }}
                                                />
                                            ),
                                        },
                                    ]}
                                    pagination={false}
                                    loading={isGettingMembers}
                                    dataSource={members?.data?.teamMembers}
                                    bordered
                                    caption={
                                        <div>
                                            <Row>
                                                <Col
                                                    span={6}
                                                    offset={18}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        marginBottom: '10px',
                                                    }}
                                                >
                                                    <Input
                                                        placeholder="Add new member"
                                                        value={memberInputValue}
                                                        onChange={(e) => {
                                                            setMemberInputValue(e.target.value);
                                                        }}
                                                        style={{
                                                            borderTopRightRadius: 0,
                                                            borderBottomRightRadius: 0,
                                                        }}
                                                    />
                                                    <Button
                                                        style={{
                                                            borderTopLeftRadius: 0,
                                                            borderBottomLeftRadius: 0,
                                                        }}
                                                        loading={isAdding}
                                                        onClick={async () => {
                                                            await addTeamMember(memberInputValue);
                                                            setMemberInputValue('');
                                                            getTeamMembers();
                                                        }}
                                                        type="primary"
                                                    >
                                                        Add
                                                    </Button>
                                                </Col>
                                                <Col span={6} offset={18} style={{ textAlign: 'center' }}>
                                                    <Text>(you can add up to 100 team members)</Text>
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                />
                            </>
                        )}
                    </Col>
                </Row>
            </Space>
        </>
    );
};

export default SubscriptionPage;
