import { Button as AntButton } from 'antd';
import { useState } from 'react';

const Button = ({ onClick, ...rest }) => {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <AntButton
            loading={isLoading}
            onClick={async () => {
                setIsLoading(true);
                await onClick();
                setIsLoading(false);
            }}
            {...rest}
        />
    );
};

export default Button;
