import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery, baseQueryWithInterceptor } from '../../../utils/query';
import { RootState } from '../../store';

export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: baseQueryWithInterceptor(
        axiosBaseQuery({
            baseUrl: '',
            prepareHeaders: (headers, { getState }) => {
                // By default, if we have a token in the store, let's use that for authenticated requests
                const token = (getState() as RootState).auth.token;
                if (token) {
                    headers.set('authorization', `Bearer ${token}`);
                }
                return headers;
            },
        }),
    ),
    endpoints: (builder) => ({
        getUser: builder.query<any, string>({
            query: (userId) => ({
                url: `/users/${userId}`,
                method: 'GET',
            }),
        }),
        getTeamMembers: builder.query<any, void>({
            query: () => ({
                url: `/team-members`,
                method: 'GET',
            }),
        }),
        addTeamMember: builder.mutation<any, string>({
            query: (member) => ({
                url: `/team-members`,
                method: 'POST',
                body: { member } as any,
            }),
        }),
        removeTeamMember: builder.mutation<any, string>({
            query: (member) => ({
                url: `/team-members`,
                method: 'DELETE',
                body: { member } as any,
            }),
        }),
    }),
});

export const { useGetUserQuery, useLazyGetTeamMembersQuery, useAddTeamMemberMutation, useRemoveTeamMemberMutation } =
    userApi;
