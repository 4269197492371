import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface AppState {
    loading: boolean;
    error: string | null;
    isSidebarCollapsed: boolean;
}

const appSlice = createSlice({
    name: 'app',
    initialState: { loading: false, error: null, isSidebarCollapsed: false } as AppState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setSidebarCollapsed: (state, action) => {
            state.isSidebarCollapsed = action.payload;
        },
    },
});

export const { actions: appActions } = appSlice;

export const selectLoading = (state: RootState) => state.app.loading;
export const selectError = (state: RootState) => state.app.error;
export const selectIsSidebarCollapsed = (state: RootState) => state.app.isSidebarCollapsed;

export default appSlice.reducer;
