import {
    Avatar,
    Button,
    Card,
    Col,
    Dropdown,
    Input,
    Layout,
    Radio,
    Row,
    Select,
    Skeleton,
    Space,
    Spin,
    theme,
    Tooltip,
    Typography,
} from 'antd';
import { FC, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import Box from '../../components/Box';
import { LoginOutlined, LogoutOutlined } from '@ant-design/icons';
import { useAppDispatch } from '../../redux/store';
import { signOutUser } from '../../redux/actions/authApi';
import { selectCurrentUser } from '../../redux/slices/auth/authSlice';
import { useSelector } from 'react-redux';
import { useGetUserQuery } from '../../redux/slices/user/api';
import { capitalize, keys, values } from 'lodash';
import { PLAN_TERMS, PLANS } from '../../configs/constants';
import {
    selectPlan,
    selectQuota,
    selectRemainingSubscribedCredit,
    selectTerm,
    selectTotalPrice,
    setPlan,
    setQuota,
    setTerm,
} from '../../redux/slices/payment/slice';
import { QUOTAS } from './constants';
import moment from 'moment';
import numeral from 'numeral';
import PaypalButton from '../../components/PaypalButton';

const { Header, Content } = Layout;
const { Text } = Typography;

export interface PaymentProps {
    children: JSX.Element;
}

const Payment: FC<PaymentProps> = () => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const dispatch = useAppDispatch();
    const currentUser = useSelector(selectCurrentUser);
    const {
        data: userResponse,
        isFetching,
        refetch: getUser,
    } = useGetUserQuery(currentUser?.email, {
        skip: !currentUser || !currentUser.email,
    });
    const { data } = userResponse || {};

    useEffect(() => {
        if (!currentUser || !currentUser.email) return;
        getUser();
    }, [currentUser]);

    const plan = useSelector(selectPlan);
    const term = useSelector(selectTerm);
    const quota = useSelector(selectQuota);
    const totalPrice = useSelector(selectTotalPrice);
    const remainingSubscribedCredit = useSelector(selectRemainingSubscribedCredit);

    const remainingMonths = useMemo(() => {
        if (!data || !data.lastPaymentEndAt) return 0;
        return moment(data.lastPaymentEndAt).diff(moment(), 'months');
    }, [data]);

    const activeTime = useMemo(() => moment(), []);

    const quotas = useMemo(() => QUOTAS[`_${term}`]?.[plan] || {}, [term, plan]);

    useEffect(() => {
        if (!QUOTAS[`_${term}`]?.[plan]?.[`_${quota}`]) {
            dispatch(setQuota(keys(quotas)[0].slice(1)));
        }
    }, [term, plan, quota]);

    return (
        <Layout style={{ minHeight: '100vh', backgroundColor: colorBgContainer }}>
            <Helmet>
                <title>PerformNoti: Payment with Paypal</title>
                <meta name="description" content="Payment with Paypal" />
            </Helmet>
            <Header style={{ backgroundColor: 'white', borderBottom: '1px solid #e7e7e7' }} className="header">
                <Row>
                    <Col span={12}>
                        <Typography
                            style={{
                                padding: '16px 0',
                                textAlign: 'left',
                            }}
                        >
                            <img
                                style={{ height: 32 }}
                                src="https://performnoti.com/wp-content/uploads/2023/04/PerformNoti-logo-resize-240x45-final.png"
                                alt="logo"
                            />
                        </Typography>
                    </Col>
                    <Col span={12}>
                        <Box d="flex" justify="flex-end">
                            <Dropdown
                                menu={{
                                    items: [
                                        {
                                            key: 'LOGOUT',
                                            label: 'Logout',
                                            icon: <LoginOutlined />,
                                        },
                                    ],
                                    onClick: (e) => {
                                        e.key === 'LOGOUT' && dispatch(signOutUser());
                                    },
                                }}
                            >
                                <Space>
                                    <Avatar src={currentUser?.photoUrl} />
                                    <Text>{currentUser?.email}</Text>
                                </Space>
                            </Dropdown>
                        </Box>
                    </Col>
                </Row>
            </Header>
            <Spin spinning={false}>
                <Content
                    style={{
                        margin: 0,
                        minHeight: 280,
                        padding: '0 50px',
                        paddingBottom: 50,
                    }}
                >
                    <Row gutter={[32, 32]} style={{ marginTop: 16 }}>
                        <Col xs={24} lg={11}>
                            <Space size="middle" style={{ width: '100%' }} direction="vertical">
                                <Typography.Title style={{ textAlign: 'left', margin: 0 }} level={3}>
                                    Your plan:
                                </Typography.Title>
                                {isFetching && <Skeleton active />}
                                {!isFetching && (
                                    <Typography>
                                        <Space style={{ width: '100%' }} direction="vertical" size="middle">
                                            <Row gutter={[8, 8]}>
                                                <Col
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'center',
                                                    }}
                                                    span={8}
                                                >
                                                    <Text strong>User Email:</Text>
                                                </Col>
                                                <Col span={16}>
                                                    <Input
                                                        value={currentUser?.email}
                                                        readOnly
                                                        addonAfter={
                                                            <Tooltip title="Use different account">
                                                                <Button
                                                                    onClick={() => dispatch(signOutUser())}
                                                                    type="link"
                                                                    size="small"
                                                                    icon={<LogoutOutlined />}
                                                                />
                                                            </Tooltip>
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                            <Row gutter={[8, 8]}>
                                                <Col
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'center',
                                                    }}
                                                    span={8}
                                                >
                                                    <Text strong>Current Plan:</Text>
                                                </Col>
                                                <Col style={{ textAlign: 'left' }} span={16}>
                                                    {capitalize(data?.plan)}
                                                </Col>
                                            </Row>
                                            {data && data?.plan != PLANS.FREE && (
                                                <>
                                                    <Row gutter={[8, 8]}>
                                                        <Col
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'flex-start',
                                                                alignItems: 'center',
                                                            }}
                                                            span={8}
                                                        >
                                                            <Text strong>Expiration Date:</Text>
                                                        </Col>
                                                        <Col style={{ textAlign: 'left' }} span={16}>
                                                            {moment(data.lastPaymentEndAt).format('MMM DD, YYYY')}
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={[8, 8]}>
                                                        <Col
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'flex-start',
                                                                alignItems: 'center',
                                                            }}
                                                            span={8}
                                                        >
                                                            <Text style={{ textAlign: 'left' }} strong>
                                                                Remaining Active Months:
                                                            </Text>
                                                        </Col>
                                                        <Col style={{ textAlign: 'left' }} span={16}>
                                                            {remainingMonths}
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={[8, 8]}>
                                                        <Col
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'flex-start',
                                                                alignItems: 'center',
                                                            }}
                                                            span={8}
                                                        >
                                                            <Text style={{ textAlign: 'left' }} strong>
                                                                Remaining Subscribed Credit:
                                                            </Text>
                                                        </Col>
                                                        <Col style={{ textAlign: 'left' }} span={16}>
                                                            {numeral(remainingSubscribedCredit).format('$0,0.00')}
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                        </Space>
                                    </Typography>
                                )}
                            </Space>
                        </Col>
                        <Col xs={24} lg={13}>
                            <Space size="middle" style={{ width: '100%' }} direction="vertical">
                                <Typography.Title style={{ textAlign: 'left', margin: 0 }} level={3}>
                                    Upgrade Your plan:
                                </Typography.Title>
                                {isFetching && (
                                    <>
                                        <Skeleton active />
                                        <Skeleton active />
                                        <Skeleton active />
                                    </>
                                )}
                                {!isFetching && (
                                    <>
                                        <Row gutter={[8, 8]}>
                                            <Col
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                }}
                                                span={8}
                                            >
                                                <Text strong>Plan:</Text>
                                            </Col>
                                            <Col style={{ textAlign: 'left' }} span={16}>
                                                <Select
                                                    value={plan}
                                                    onChange={(e) => {
                                                        dispatch(setPlan(e));
                                                    }}
                                                    style={{ width: '100%', maxWidth: 300 }}
                                                >
                                                    <Select.Option value={PLANS.INDIVIDUAL}>Individual</Select.Option>
                                                    <Select.Option value={PLANS.TEAM}>Team</Select.Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                }}
                                                span={8}
                                            >
                                                <Text strong>Plan Term:</Text>
                                            </Col>
                                            <Col style={{ textAlign: 'left' }} span={16}>
                                                <Radio.Group
                                                    value={term}
                                                    onChange={(e) => {
                                                        dispatch(setTerm(e.target.value));
                                                    }}
                                                >
                                                    {values(PLAN_TERMS).map((term) => (
                                                        <Radio value={term} key={term}>
                                                            {term} months
                                                        </Radio>
                                                    ))}
                                                </Radio.Group>
                                            </Col>
                                        </Row>
                                        <Box text="left">
                                            <Text>
                                                <Text strong>Choose A Monthly Email Quota Below </Text>:
                                            </Text>
                                        </Box>
                                        <Radio.Group
                                            value={quota}
                                            onChange={(e) => {
                                                dispatch(setQuota(e.target.value));
                                            }}
                                            style={{ width: '100%' }}
                                        >
                                            <Box w="100%" d="flex" direction="column" items="flex-start">
                                                {keys(quotas).map((quota) => (
                                                    <Radio
                                                        style={{
                                                            width: '100%',
                                                            marginBottom: '10px',
                                                        }}
                                                        value={quota.slice(1)}
                                                        key={quota.slice(1)}
                                                    >
                                                        <Text>{quota.slice(1)} </Text>
                                                    </Radio>
                                                ))}
                                            </Box>
                                        </Radio.Group>
                                        <Box text="left">
                                            <Text>
                                                Please be noted that you cannot downgrade your plan regarding quota or
                                                plan term after purchase
                                            </Text>
                                        </Box>
                                        <Box text="left">
                                            <Text strong>All plans above include: support within 48 working hours</Text>
                                        </Box>
                                        <Card size="small" bordered style={{ width: '100%' }}>
                                            <Text>
                                                Active Time for This Plan:{' '}
                                                <Text strong>{activeTime.format('MMM DD, YYYY')}</Text> to{' '}
                                                <Text strong>
                                                    {activeTime.clone().add(term, 'months').format('MMM DD, YYYY')}
                                                </Text>
                                            </Text>
                                            <br />
                                            <Text italic>Note: we do not automatically renew your plan</Text>
                                            <br />
                                            <Text italic>
                                                (you can manually renew 30 days before subscription ends).
                                            </Text>
                                            <br />
                                            <Space style={{ width: '100%', marginTop: 8 }} direction="vertical">
                                                <Row gutter={8} style={{ whiteSpace: 'nowrap' }}>
                                                    <Col style={{ textAlign: 'right' }} span={10}>
                                                        <Text>Fee:</Text>
                                                    </Col>
                                                    <Col style={{ textAlign: 'left' }} span={8} offset={2}>
                                                        <Text strong>
                                                            {numeral(quotas[`_${quota}`]).format('$0,0.00')}
                                                        </Text>
                                                        <Text>
                                                            ( {numeral(quotas[`_${quota}`] / term).format('$0,0.00')}{' '}
                                                            per month )
                                                        </Text>
                                                    </Col>
                                                </Row>
                                                <Row gutter={8} style={{ whiteSpace: 'nowrap' }}>
                                                    <Col style={{ textAlign: 'right' }} span={10}>
                                                        <Text>Support Fee:</Text>
                                                    </Col>
                                                    <Col style={{ textAlign: 'left' }} span={8} offset={2}>
                                                        <Text strong>$0.00 </Text>
                                                        <del style={{ fontWeight: 'normal' }}>
                                                            (${data && data?.plan === PLANS.INDIVIDUAL ? '2' : '5'} per
                                                            month)
                                                        </del>
                                                    </Col>
                                                </Row>
                                                <Row gutter={8}>
                                                    <Col style={{ textAlign: 'right' }} span={10}>
                                                        <Text>Discount:</Text>
                                                    </Col>
                                                    <Col style={{ textAlign: 'left' }} span={8} offset={2}>
                                                        <Text strong>- $0.00 </Text>
                                                    </Col>
                                                </Row>
                                                {remainingSubscribedCredit > 0 && (
                                                    <Row gutter={8}>
                                                        <Col style={{ textAlign: 'right' }} span={10}>
                                                            <Text>Remaining Credit Deduction:</Text>
                                                        </Col>
                                                        <Col style={{ textAlign: 'left' }} span={8} offset={2}>
                                                            <Text strong>
                                                                - {numeral(remainingSubscribedCredit).format('$0,0.00')}
                                                            </Text>
                                                        </Col>
                                                    </Row>
                                                )}
                                                <Row
                                                    style={{ borderTop: `1px solid #e7e7e7`, paddingTop: 8 }}
                                                    gutter={8}
                                                >
                                                    <Col style={{ textAlign: 'right' }} span={10}>
                                                        <Text>Total:</Text>
                                                    </Col>
                                                    <Col style={{ textAlign: 'left' }} span={8} offset={2}>
                                                        <Text strong>{numeral(totalPrice).format('$0,0.00')}</Text>
                                                    </Col>
                                                </Row>
                                            </Space>
                                        </Card>
                                        <PaypalButton />
                                    </>
                                )}
                            </Space>
                        </Col>
                    </Row>
                </Content>
            </Spin>
        </Layout>
    );
};

export default Payment;
