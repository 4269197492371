import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery, baseQueryWithInterceptor } from '../../../utils/query';
import { RootState } from '../../store';

export const paymentApi = createApi({
    reducerPath: 'paymentApi',
    baseQuery: baseQueryWithInterceptor(
        axiosBaseQuery({
            baseUrl: '/payments',
            prepareHeaders: (headers, { getState }) => {
                // By default, if we have a token in the store, let's use that for authenticated requests
                const token = (getState() as RootState).auth.token;
                if (token) {
                    headers.set('authorization', `Bearer ${token}`);
                }
                return headers;
            },
        }),
    ),
    endpoints: (builder) => ({
        pay: builder.mutation<any, any>({
            query: (data) => ({
                url: '/pay',
                method: 'POST',
                body: data,
            }),
        }),
    }),
});

export const { usePayMutation } = paymentApi;
