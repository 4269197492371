import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { signInWithPopup, signInWithRedirect, signOut } from 'firebase/auth';
import { auth, authProvider } from '../../configs/firebase';

export const authApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        prepareHeaders: (headers, { getState }) => {
            // By default, if we have a token in the store, let's use that for authenticated requests
            const token = (getState() as RootState).auth.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        login: builder.mutation<any, any>({
            query: (credentials) => ({
                url: 'login',
                method: 'POST',
                body: credentials,
            }),
        }),
        refreshToken: builder.mutation<any, any>({
            query: (refreshToken: string) => ({
                url: 'refresh_token',
                method: 'POST',
                body: {
                    refreshToken: refreshToken,
                },
            }),
        }),
    }),
});

export const { useLoginMutation, useRefreshTokenMutation } = authApi;

export const authWithGoogle = createAsyncThunk('auth/googleAuth', async (params, thunkAPI) => {
    try {
        const credential: any = await signInWithPopup(auth, authProvider);
        console.log('credential', credential);
        return credential._tokenResponse;
    } catch (err) {
        return thunkAPI.rejectWithValue({
            code: err.code,
            messsage: err.message,
        });
    }
});

export const signOutUser = createAsyncThunk('auth/signOut', async (params, thunkAPI) => {
    try {
        await signOut(auth);
    } catch (err) {
        console.log('signOutUser', err);
        return thunkAPI.rejectWithValue('Sign out error!');
    }
});
