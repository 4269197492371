import { useEffect } from 'react';
import { message, Table } from 'antd';
import { useParams } from 'react-router-dom';
import { useLazyGetFormByIdQuery, useResendEmailMutation } from '../../redux/slices/form/formApi';
import { selectCurrentUser } from '../../redux/slices/auth/authSlice';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Box from '../../components/Box';
import Button from '../../components/Button';

const FormDetail = () => {
    const [getFormById, { data, isLoading }] = useLazyGetFormByIdQuery();
    const [resendEmail, { data: resendResult }] = useResendEmailMutation();
    const { formId } = useParams();
    const currentUser = useSelector(selectCurrentUser);

    useEffect(() => {
        if (!currentUser) return;
        getFormById({ formId, userId: currentUser.email });
    }, []);

    useEffect(() => {
        if (!resendResult) return;
        if (resendResult.success) {
            message.success('Resend email successfully');
        } else {
            message.error('Resend email failed');
        }
    }, [resendResult]);

    return (
        <Box h="100%" w="100%">
            <Table
                loading={isLoading}
                dataSource={data?.data.submissionData}
                bordered
                tableLayout="fixed"
                pagination={{
                    pageSize: 10,
                    total: data?.data.total,
                    showQuickJumper: true,
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '50', '100'],
                }}
                rowKey={(record) => record.id}
                columns={[
                    {
                        title: '#',
                        key: 'incrementId',
                        dataIndex: 'uniqueIncrementId',
                    },
                    {
                        title: 'Request Date',
                        dataIndex: 'createTime',
                        key: 'createTime',
                        render: (text) => moment(new Date(text)).format('MMM DD, YYYY HH:MM'),
                        sorter: (a, b) => Number(new Date(a.createTime)) - Number(new Date(b.createTime)),
                    },
                    {
                        title: 'Action',
                        dataIndex: 'id',
                        key: 'id',
                        align: 'center',
                        width: 180,
                        render: (text, record) => (
                            <Button
                                onClick={async () => {
                                    await resendEmail(record.id);
                                }}
                            >
                                Resend Email
                            </Button>
                        ),
                    },
                ]}
            />
        </Box>
    );
};

export default FormDetail;
