import React, { useMemo } from 'react';
import { LoginOutlined, TeamOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Avatar, Breadcrumb, Col, Dropdown, Layout, Menu, Row, Space, theme, Typography } from 'antd';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PATHS } from '../configs/constants';
import { isEmpty, startCase } from 'lodash';
import { useSelector } from 'react-redux';
import { appActions, selectIsSidebarCollapsed } from '../redux/slices/app/appSlice';
import { selectCurrentUser } from '../redux/slices/auth/authSlice';
import Box from '../components/Box';
import { signOutUser } from '../redux/actions/authApi';
import { useAppDispatch } from '../redux/store';
import { Helmet } from 'react-helmet';

const { Header, Content, Sider } = Layout;
const { Text } = Typography;

const AdminLayout: React.FC = () => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const navigte = useNavigate();
    const { pathname } = useLocation();
    const { formId } = useParams();

    const currentUser = useSelector(selectCurrentUser);
    const isSidebarCollapsed = useSelector(selectIsSidebarCollapsed);
    const dispatch = useAppDispatch();

    const breakcrumbs = useMemo(
        () =>
            pathname
                .split('/')
                .filter((item) => item !== '' && item !== formId)
                .map((item) => startCase(item)),
        [pathname, formId],
    );

    return (
        <>
            <Helmet>
                <title>PerformNoti: Form Management</title>
                <meta name="description" content="Form Management" />
            </Helmet>
            <Layout style={{ minHeight: '100vh' }}>
                <Sider
                    collapsible
                    collapsed={isSidebarCollapsed}
                    onCollapse={(value) => {
                        dispatch(appActions.setSidebarCollapsed(value));
                    }}
                    breakpoint="lg"
                    onBreakpoint={(broken) => {
                        dispatch(appActions.setSidebarCollapsed(broken));
                    }}
                    style={{
                        height: '100vh',
                    }}
                >
                    <Typography
                        style={{
                            padding: '16px 24px',
                        }}
                    >
                        <Space direction="horizontal" size="middle">
                            <img
                                style={{ height: 32 }}
                                src="http://performnoti.com/wp-content/uploads/2023/04/cropped-PerformNoti-Logo-Without-Text-512x512-1.png"
                                alt="logo"
                            />
                            {!isSidebarCollapsed && (
                                <Typography.Title
                                    style={{
                                        color: '#fff',
                                        margin: 0,
                                    }}
                                    level={5}
                                >
                                    PerformNoti
                                </Typography.Title>
                            )}
                        </Space>
                    </Typography>
                    <Menu
                        theme="dark"
                        mode="inline"
                        onClick={(e) => {
                            navigte(e.key);
                        }}
                        selectedKeys={[pathname]}
                        items={[
                            {
                                key: `/`,
                                icon: <TeamOutlined />,
                                label: 'Subscription',
                            },
                            {
                                key: `/${PATHS.FORM_MANAGEMENT}`,
                                icon: <UnorderedListOutlined />,
                                label: 'Manage Forms',
                            },
                        ]}
                    />
                </Sider>
                <Layout>
                    <Header style={{ backgroundColor: 'white' }} className="header">
                        <Row>
                            <Col span={12}></Col>
                            <Col span={12}>
                                <Box d="flex" justify="flex-end">
                                    <Dropdown
                                        menu={{
                                            items: [
                                                {
                                                    key: 'LOGOUT',
                                                    label: 'Logout',
                                                    icon: <LoginOutlined />,
                                                },
                                            ],
                                            onClick: (e) => {
                                                e.key === 'LOGOUT' && dispatch(signOutUser());
                                            },
                                        }}
                                    >
                                        <Space>
                                            <Avatar src={currentUser?.photoUrl} />
                                            <Text>{currentUser?.email}</Text>
                                        </Space>
                                    </Dropdown>
                                </Box>
                            </Col>
                        </Row>
                    </Header>
                    <Layout style={{ padding: '0 24px 24px' }}>
                        {!isEmpty(breakcrumbs) ? (
                            <Breadcrumb style={{ margin: '16px 0' }}>
                                {breakcrumbs.map((item, index) => (
                                    <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
                                ))}
                            </Breadcrumb>
                        ) : (
                            <Breadcrumb style={{ margin: '16px 0' }}>
                                <Breadcrumb.Item>Subscription</Breadcrumb.Item>
                            </Breadcrumb>
                        )}

                        <Content
                            style={{
                                padding: 24,
                                margin: 0,
                                minHeight: 280,
                                background: colorBgContainer,
                            }}
                        >
                            <Outlet />
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        </>
    );
};

export default AdminLayout;
