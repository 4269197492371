import { useEffect, useMemo } from 'react';
import { useLazyGetFormsByUserQuery } from '../../redux/slices/form/formApi';
import type { MenuProps } from 'antd';
import { Alert, Button, Card, Dropdown, Space, Tag, Typography } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import Box from '../../components/Box';
import { toString } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../redux/slices/auth/authSlice';

const FormCard = styled(Card)`
    width: 300px;
    .ant-card-head {
        border-bottom: none;
    }
    .ant-card-head-title,
    .ant-card-body {
        text-align: left;
    }
`;
const { Title, Text } = Typography;

const FormManagement = () => {
    const currentUser = useSelector(selectCurrentUser);
    const [getFormsByUser, { isFetching, data, error }] = useLazyGetFormsByUserQuery();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    useEffect(() => {
        if (!currentUser) return;
        getFormsByUser(currentUser.email);
    }, [currentUser, getFormsByUser]);

    const forms = useMemo(() => {
        if (!data || !data.success) return [];
        return data.data;
    }, [data]);

    return (
        <Space size={16} direction="vertical" style={{ width: '100%' }}>
            {(error || (data && !data.success)) && (
                <Alert message="Error: Something went wrong!" type="error" showIcon />
            )}
            <Space size={16} wrap style={{ width: '100%' }}>
                {isFetching && (
                    <>
                        <FormCard size="small" loading />
                        <FormCard size="small" loading />
                        <FormCard size="small" loading />
                        <FormCard size="small" loading />
                        <FormCard size="small" loading />
                        <FormCard size="small" loading />
                        <FormCard size="small" loading />
                        <FormCard size="small" loading />
                    </>
                )}
                {!isFetching &&
                    forms.map((form) => (
                        <FormCard
                            onClick={() => {
                                // navigate to form detail
                                navigate(`${pathname}/${form.id}`);
                            }}
                            hoverable
                            key={form.id}
                            size="small"
                            title={
                                // <Tag color="orange">0 Responses</Tag>
                                <Title ellipsis style={{ margin: 0 }} level={4}>
                                    {form.name}
                                </Title>
                            }
                            extra={
                                <Dropdown
                                    menu={{
                                        items: [
                                            {
                                                label: 'Open Form',
                                                key: 0,
                                            },
                                            form.responseSheetUrl && {
                                                label: 'Response Sheet',
                                                key: 1,
                                            },
                                            // {
                                            //   type: 'divider',
                                            // },
                                            // {
                                            //   label: 'Deactivate Form',
                                            //   key: 2,
                                            //   danger: true,
                                            // },
                                        ].filter(Boolean) as MenuProps['items'],
                                        onClick: ({ key, domEvent }) => {
                                            domEvent.stopPropagation();

                                            switch (toString(key)) {
                                                case '0':
                                                    window.open(
                                                        `https://docs.google.com/forms/d/${form.id}/edit`,
                                                        '_blank',
                                                    );
                                                    break;
                                                case '1':
                                                    window.open(form.responseSheetUrl, '_blank');
                                                    break;
                                                case '2':
                                                    // call deactivate form api
                                                    break;
                                                default:
                                                    break;
                                            }
                                        },
                                    }}
                                >
                                    <Button type="text">Actions</Button>
                                </Dropdown>
                            }
                        >
                            <Space
                                style={{ width: '100%', height: 40, justifyContent: 'flex-end' }}
                                size={24}
                                direction="vertical"
                            >
                                <Box w="100%" d="flex" justify="space-between">
                                    <Text type="secondary">
                                        Last saved {moment(form.lastSaveAt).format('MMM DD, YYYY')}{' '}
                                    </Text>
                                    <Tag color="success">Activate</Tag>
                                </Box>
                            </Space>
                        </FormCard>
                    ))}
            </Space>
        </Space>
    );
};

export default FormManagement;
