import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery, baseQueryWithInterceptor } from '../../../utils/query';
import { RootState } from '../../store';

export const formApi = createApi({
    reducerPath: 'formApi',
    baseQuery: baseQueryWithInterceptor(
        axiosBaseQuery({
            baseUrl: '',
            prepareHeaders: (headers, { getState }) => {
                // By default, if we have a token in the store, let's use that for authenticated requests
                const token = (getState() as RootState).auth.token;
                if (token) {
                    headers.set('authorization', `Bearer ${token}`);
                }
                return headers;
            },
        }),
    ),
    endpoints: (builder) => ({
        getFormsByUser: builder.query<
            {
                data: Record<string, any>[];
                success: boolean;
            },
            string
        >({
            query: (userId) => ({
                url: '/forms',
                method: 'POST',
                body: {
                    userId: userId,
                } as never,
            }),
        }),
        getFormById: builder.query<
            {
                data: {
                    submissionData: Record<string, any>[];
                    total: number;
                };
                success: boolean;
            },
            { formId: string; userId: string }
        >({
            query: ({ formId }) => ({
                url: '/form-detail',
                method: 'POST',
                body: {
                    formId: formId,
                } as never,
            }),
        }),
        resendEmail: builder.mutation<
            {
                success: boolean;
                message: string;
            },
            string
        >({
            query: (submissionId) => ({
                url: '/resend-email',
                method: 'POST',
                body: {
                    submissionId: submissionId,
                } as never,
            }),
        }),
    }),
});

export const { useLazyGetFormsByUserQuery, useLazyGetFormByIdQuery, useResendEmailMutation } = formApi;
