import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import authSlice from './slices/auth/authSlice';
import { authApi } from './actions/authApi';
import appSlice from './slices/app/appSlice';
import { formApi } from './slices/form/formApi';
import { userApi } from './slices/user/api';
import { paymentSlice } from './slices/payment/slice';
import { paymentApi } from './slices/payment/api';

export const store = configureStore({
    reducer: {
        auth: authSlice,
        app: appSlice,
        payment: paymentSlice.reducer,
        [authApi.reducerPath]: authApi.reducer,
        [formApi.reducerPath]: formApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [paymentApi.reducerPath]: paymentApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            authApi.middleware,
            formApi.middleware,
            userApi.middleware,
            paymentApi.middleware,
        ),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
