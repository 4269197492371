export const QUOTAS = {
    _3: {
        INDIVIDUAL: {
            _1000: 4 * 3,
            _1500: 5 * 3,
            _2000: 6 * 3,
            _2500: 7 * 3,
            _3000: 8 * 3,
            _4000: 10 * 3,
            _5000: 12 * 3,
            _6000: 14 * 3,
            _7000: 16 * 3,
        },
        TEAM: {
            _5000: 16 * 3,
            _10000: 20 * 3,
            _15000: 24 * 3,
            _20000: 28 * 3,
            _25000: 32 * 3,
            _50000: 52 * 3,
        },
    },
    _12: {
        INDIVIDUAL: {
            _1000: 3 * 12,
            _1500: 3.75 * 12,
            _2000: 4.5 * 12,
            _2500: 5.25 * 12,
            _3000: 6 * 12,
            _4000: 7.5 * 12,
            _5000: 9 * 12,
            _6000: 10.5 * 12,
            _7000: 12 * 12,
            _8000: 13.5 * 12,
            _9000: 15 * 12,
            _10000: 16.5 * 12,
        },
        TEAM: {
            _5000: 12 * 12,
            _10000: 15 * 12,
            _15000: 18 * 12,
            _20000: 21 * 12,
            _25000: 24 * 12,
            _50000: 40 * 12,
        },
    },
};
