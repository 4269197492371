import { useSelector } from 'react-redux';
import {
    selectAuthError,
    selectAuthResult,
    selectCurrentUser,
    selectIsAuthenticated,
    setAuthRedirectResult,
} from './redux/slices/auth/authSlice';
import { useAppDispatch } from './redux/store';
import { useEffect, useRef } from 'react';
import { getRedirectResult } from 'firebase/auth';
import { auth } from './configs/firebase';
import { authWithGoogle, signOutUser, useLoginMutation } from './redux/actions/authApi';
import { App, Image, Space, Typography } from 'antd';
import { ALLOW_SIGNIN_POPUP_IMAGE } from './configs/constants';

interface Props {
    component: React.ComponentType;
    path?: string;
    // roles: Array<ROLE>;
}

export const PrivateRoute: React.FC<Props> = ({ component: RouteComponent }) => {
    const dispatch = useAppDispatch();
    const { modal } = App.useApp();

    const currentUser = useSelector(selectCurrentUser);
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const authResult = useSelector(selectAuthResult);
    const authError = useSelector(selectAuthError);

    const [loginUserFunc] = useLoginMutation();
    // const userHasRequiredRole = user && roles.includes(user.role) ? true : false;

    useEffect(() => {
        console.log({ currentUser, isAuthenticated });

        if (!currentUser) {
            const handleGetCurrentUser = async () => {
                if (authResult) {
                    await loginUserFunc({ accessToken: authResult.idToken });
                    dispatch(setAuthRedirectResult(authResult));
                    return;
                }
                dispatch(authWithGoogle());
            };
            handleGetCurrentUser();
        } else if (!isAuthenticated) {
            dispatch(signOutUser());
        }
    }, [dispatch, currentUser, authResult]);

    const popup = useRef(null);
    useEffect(() => {
        if (popup.current) {
            popup.current.destroy();
        }
        if (authError) {
            popup.current = modal.warning({
                icon: null,
                width: 'auto',
                content: (
                    <Space direction="vertical" size="middle" style={{ display: 'flex', textAlign: 'center' }}>
                        <Image src={ALLOW_SIGNIN_POPUP_IMAGE} height={400} width="auto" />
                        <Typography.Text
                            style={{
                                fontSize: 20,
                            }}
                        >
                            Please select "Always allow pop-ups and redirects from https://apps.performnoti.com" to
                            continue logging in PerformNoti.
                        </Typography.Text>
                    </Space>
                ),
            });
        } else if (popup.current) {
            popup.current.destroy();
        }
        return () => {
            if (popup.current) {
                popup.current.destroy();
            }
        };
    }, [authError]);

    if (!isAuthenticated) return null;

    return <RouteComponent />;
};
